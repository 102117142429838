@import "../../../../styles/flex";
@import "../../../../styles/dimentions";

.createOrEditInstitutionFormContainer {
    padding: 24px;

    .createOrEditInstitutionForm {
        @include flex(row, start, start);
        flex-wrap: wrap;
        padding: 10px;

        .inputContainer{
            margin: 10px;
            width: 100%;

            @media (max-width: $tablet) {
                width: 100%;
            }

            .fieldCharacter{
                * {
                    span {
                        color: red !important; 
                    }
                   
                }
            }
            .smallCounter{
                margin-top: 0;
            }
        }
    }
}
