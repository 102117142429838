@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";
@import "../../styles/dimentions";

.newRegister {
	@include flex(column, center, center);

	width: 100%;
	height: 100%;
	background-color: #E8E8E8;

	@media (max-width: $mobile) {
		height: 50em;
	}

	&Container {
		max-width: 800px;
		margin: 10px auto;
	}


	.buttonsContainer {
		@include flex(column, center, center);

		margin-top: 10px;

        .normalButton {
            margin-bottom: 12px;
            min-height: 40px;
            min-width: 120px;
        }
	}
	.smallCounter{
		margin-top: 0;
	}
}

.card {
	padding: 30px;

	@media (max-width: $mobile) {
		padding-top: 60px;
	}
}
