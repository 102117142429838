.tableRowRoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    padding: 10px;

    .roundImage {
        overflow: hidden;
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }
}