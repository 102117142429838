@import "../../styles/flex";
@import "../../styles/colors";
@import "../../../node_modules/cropperjs/dist/cropper.css";

.singleImageCase {
    padding-top: 14px;
    padding-bottom: 14px;

    h6 {
        padding-bottom: 10px;
    }

    .singleImageUploadeButtonContainer {
        width: 100%;
        height: 100%;

        .buttonTitle {
            margin-left: 4px;
        }
    }

    .cropContainer {
        @include flex(column, center, flex-end);

        .cropContainerCropperContainer {
            @include flex(column, center, center);
            width: 100%;

            .cropContainerCropperContainerRotateButtons {
                @include flex(row, space-between, center);
                margin: 8px;
                .rotateIconButton {
                    margin: 10px;
                    background: $secondary-color;
                    * {
                        color: $white;
                    }
                }
            }
        }

        .cropContainerButtonsContainer {
            @include flex(column, end, center);

            * {
                margin-top: 10px;
                margin-right: 10px;
            }
        }
    }
}
