@import "../../styles/dimentions";

.dashboard {
    .pageContainers {
        padding: 20px;
        margin-top: 65px;

        @media (max-width: $tablet) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
