@import "../../../styles/flex";
@import "../../../styles/colors";

.filterAutosuggestContainer {
    @include flex(column, flex-start, flex-end);

    .filterAutosuggestTopBar {
        @include flex(column, center, flex-end);

        width: 100%;
        background-color: $secondary-color
    }

    .filterAutosuggestContentContainer {
        @include flex(column, center, flex-end);

        min-width: 300px;
        min-height: 400px;

        .autosuggestContainer {
            width: 100%;
        }

        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 4px;
    }

    overflow: visible;
}
