@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";

.login {
	@include flex(column, center, center);

	width: 100%;
	height: 100%;

	img {
		width: 300px;
	}

	&Card {
		@extend %card;
		min-width: 20%;
	}

	.loginButton {
		margin: 10px;
	}

	.recoveryLink {
		font-size: 14px;
	}

	.registerLink {
		margin-top: 20px;
		font-size: 14px;
	}
}



