@import "../../../../styles/flex";
@import "../../../../styles/dimentions";

.createOrEditCourseFormContainer {
    padding: 24px;

    .createOrEditCourseForm {
        @include flex(row, start, start);
        flex-wrap: wrap;
        padding: 10px;

        .inputContainer {
            margin: 10px;
            box-sizing: border-box;
            width: 100%;

            @media (max-width: $tablet) {
                width: 100%;
            }

            .fieldCharacter{
                * {
                    span {
                        color: red !important; 
                    }
                   
                }
            }
        }
    }
}
