@import "../../styles/flex";
@import "../../styles/colors";


.imageItem {
    @include flex(column, center, center);
    position: relative;
    max-width: 200px;
    height:  150px;
    border-radius: 5px;
    right: 0;
    left: 0;
    padding: 0;
    overflow: hidden;
    margin: 5px;
    background-color: $light-gray;

    img {
        height: 100%;
    }

    .deleteBtnContainer {
        @include flex(column, center, center);
        z-index: 100;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba($color: #050505, $alpha: 0.4);
        border-radius: 50px;
        margin: 4px;

        svg {
            * {
                color: rgba($light-gray, $alpha: 0.8);
            }
        }
        svg:hover {
            * {
                color: rgba($light-gray, $alpha: 0.8);
            }
        }
    }

    .cropBtnContainer {
        @include flex(column, center, center);
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #050505, $alpha: 0.4);
        border-radius: 50px;
        margin: 4px;

        svg {
            * {
                color: rgba($light-gray, $alpha: 0.8);
            }
        }
        svg:hover {
            * {
                color: rgba($light-gray, $alpha: 0.8);
            }
        }
    }

    .darkOverlay {
        z-index: 80;
        @include flex(column, center, center);
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        box-sizing: border-box;
        padding: 16px;
        background-color: rgba($color: #050505, $alpha: 0.6);
        color: white;

        svg {
            * {
                color: rgba($light-gray, $alpha: 0.7);
            }
        }
        svg:hover {
            * {
                color: rgba($light-gray, $alpha: 1);
            }
        }
    }
}
