.tableRowCellContainer {

    box-sizing: border-box;
    padding: 3px 5px;
    border-radius: 4px;

    &Yellow {
        font-weight: bold;
        background-color: #ffe760;
    }
    &red {
        font-weight: bold;
        background-color: #ff4e32;
    }
    &Green {
        font-weight: bold;
        background-color: #59ff72;
    }
    &Blue {
        font-weight: bold;
        background-color: #59a6ff;
    }

}