@import "../../../../styles/flex";
@import "../../../../styles/dimentions";

.createOrEditUserFormContainer {
    padding: 24px;
    padding-bottom: 0;

    .createOrEditUserForm {
        @include flex(row, start, start);
        flex-wrap: wrap;
        padding: 10px;

        @media (max-width: $tablet) {
            padding: 0;
        }

        .createOrEditUserFormSection {
            margin: 10px;
            width: calc(100% - 50% - 20px);

            @media (max-width: $tablet) {
                margin: 0px;
                width: 100%;
            }
            .fieldCharacter{
                * {
                    span {
                        color: red !important; 
                    }
                   
                }
            }
            .smallCounter{
                margin-top: 0 !important;
            }
            .fieldInputs{
                margin-top: 35px;
            }
        }

        .createOrEditUserFormFullWidthSection {
            margin: 10px;
            width: 100%;

            @media (max-width: $tablet) {
                margin: 0px;
            }
        }

        .createOrEditUserFormFooter {
            @include flex(column, flex-end, flex-end);

            margin: 10px;
            width: 100%;
            
            .buttonsContainer {
                @include flex(row, flex-end, center);
                margin-bottom: 0;
                width: 100%;
                .normalButton {
                    margin-right: 18px;
                }

                @media (max-width: $mobile) {
                    @include flex(column, center, center);
                    .normalButton {
                        margin-bottom: 16px;
                        margin-right: 0px;
                        width: 100%;
                    }
                }
            }
            @media (max-width: $tablet) {
                @include flex(column, center, center);
            }
        }
    }
}
.cpfCharacter{
    * {
        span {
            color: red !important; 
        }
       
    }
}
