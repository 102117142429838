@import "../../styles/flex";

.superiorCardContainer {
    @include flex(column, center, center);

    margin: 10px;
    padding: 16px;

    .superiorCardContainerSubtitle {
        padding-top: 0px;
    }

    .contactsContainer {
        @include flex(row, center, center);

        .contactContainer {
            @include flex(column, center, center);

            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
