@import "../../styles/flex";

.confirmPrintDialog {
    @include flex(row, flex-start, center);

    width: 100%;
    background-color: #fff;

    &Row {
        @include flex(row, start, center);

        flex-wrap: wrap;
        padding-top: 20px;
        box-sizing: border-box;
        width: 100%;
    }
}

.cardPrintContainer {
    @include flex(column, flex-start, flex-start);

    width: 243px;
    height: 153px;
    box-sizing: border-box;
    padding: 0 10px;
    background-color: white;
    text-transform: uppercase;
    font-weight: bold;

    p.text {
        margin: 0;
        padding: .5px 0;
        font-size: 9px;
        color: #343436;
    }

    p.text.first-level-data {
        padding-left: 20px;
    }

    p.text.second-level-data {
        text-align: center;
    }

    &Info {
        @include flex(row, flex-start, flex-start);

        width: 100%;
        margin-top: 8px;

        .verticalWrapper {
            margin: 2px 18px;
        }

        img {
			width: 75px;
			height: 98px;
			object-fit: cover;
        }

        .confirmCardData {
            @include flex(column, flex-start, flex-start);

            .title {
                color: #343436;
                font-weight: bold;
            }
        }
    }

    .qrCodeRow {
        @include flex(row, flex-start, center);

        box-sizing: border-box;
        padding: 5px;

        img {
            width: 40px;
            padding-left: 15px;
        }

        p {
            margin: 0;
            padding: 0;
            padding-left: 100px;
            font-size: 9px;
            color: #343436;
        }
    }
}
