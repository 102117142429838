@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";

.emailRecovery {
	@include flex(column, center, center);
	width: 100%;
	height: 100%;

	img {
		max-width: 300px;
	}

	&Card {
		@extend %card;
	}

	.emailRecoveryButton {
		margin: 10px;
	}
}
