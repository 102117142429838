@import "./flex";

%card {

    @include flex(column, start, center);
    box-sizing: border-box;
    margin: 0;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
                0px 1px 1px 0px rgba(0,0,0,0.14),
                0px 2px 1px -1px rgba(0,0,0,0.12);
}
