@import "../../../styles/flex";
@import "../../../styles/colors";

.filterEnumPickerContainer {
    @include flex(column, center, flex-end);

    .filterEnumPickerTopBar {
        @include flex(column, center, flex-end);

        width: 100%;
        background-color: $secondary-color;
    }

    .filterEnumPickerContentContainer {
        @include flex(column, center, flex-end);

        min-width: 200px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 4px;
    }
}
