@import "../../styles/colors";


.selectionIndicator {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin-right: 18px;
	padding-right: 12px;
	border-radius: 20px;
	background-color: lightgrey;

	* {
		font-weight: bold;
	}
}