@import "../../styles/colors";
@import "../../styles/flex";


.cardPrint {
	@include flex(column, flex-start, flex-start);

	width: 400px;
	height: 300px;
	box-sizing: border-box;
	// padding: 20px 40px;
	padding: 0 10px;
	background-color: white;
	border-radius: 12px;
	box-shadow: 0px 1px 5px 0px gray;
	margin: 20px;

	&Info {
		@include flex(row, flex-start, flex-start);

		width: 100%;
		margin-top: 25px;

		img {
			width: 150px;
			height: 180px;
			object-fit: cover;
		}

		.confirmCardData {
			@include flex(column, flex-start, flex-start);

			.title {
				color: #343436;
				font-weight: bold;
			}
		}
	}

	.horizontalWrapper {
		display: flex;
		justify-content: flex-start;
		font-size: 10px;
		margin-left: 10px;

		.TitleCard {
			color: rgb(0, 0, 0);
			margin-right: 5px;
		}
	}

	.qrCodeRow {
		@include flex(row, flex-start, center);

		box-sizing: border-box;
		padding: 5px;

		img {
			width: 70px;
			padding-left: 32px;
			padding-right: 40px;
		}
	}
}
