@import "../../styles/colors";

.closeButtonContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 10px;
}

.toolbar {
    background-color: $primary-color
}
