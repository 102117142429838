@import "../../../../../styles/flex";
@import "../../../../../styles/colors";

.editUsersTableContainer {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    box-sizing: border-box;

    &DragDropContextContainer {
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;

        * {
            margin: 8px;
        }
    }
}

.columnRowContainer {
    background-color: $super-light-gray;
    padding: 16px;
    max-width: 50%;
    width: 250px;
    border-radius: 4px;
}

.columnRowDraggingContainer {
    @extend .columnRowContainer;
    background-color: rgba($primary-color, 0.7);
}

.columnRowContainerItem {
    user-select: none;
    padding: 16px;
    margin-left: 8px;
    background: $semi-light-gray;
    border-radius: 4px;
}

.columnRowDraggingContainerItem {
    @extend .columnRowContainerItem;
    background: $secondary-color;
}
