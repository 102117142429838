.container {
	display: flex;
	max-width: max-content;
	gap: 10px;
}

.miniCard{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: black;
	background: white;
	padding: 10px;
	gap: 10px;
}

.miniCard h6 {
	margin: 0px;
	font-size: 18px;
}

.miniCard p {
	margin: 0px;
}