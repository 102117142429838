@import "./styles/colors";

* {
    font-family: 'Muli', sans-serif;
    color: $common-font-color;
}

#app, body, html {
    margin: 0;
    padding: 0;
    background-color: $light-gray;
    height: 100%;
}
