@import "../../../../styles/flex";
@import "../../../../styles/dimentions";

.teamMemberEditor {
    padding: 24px;

    .buttonsContainer {
        @include flex(row, flex-end, center);
        margin-top: 10px;
        .normalButton {
            margin-right: 12px;
            min-height: 40px;
            min-width: 120px;   
        }
    }
}
